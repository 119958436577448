<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <employee-left-panel :data-item='dataItem'></employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label="Yardım" icon="pi pi-question-circle" @click="visibleLeft = true" class="p-button-danger mr-2 p-2 pl-3 pr-3" />
                
				<Sidebar v-model:visible="visibleLeft" :baseZIndex="1000">
                    <h3>Kişisel Bilgiler</h3>

                    <p class="text-lg">

						<b>Öğrenim Durumu:</b> Personelin mezun olduğu kurumun statüsünü seçin.<br>
                        <br>
                        <b>Mezun Olduğu Kurum:</b> Personelin mezun olduğu son kurumu giriniz. Bu alan zorunlu değildir.<br>
						<br>
                        <b>Askerlik Durumu:</b> Personelinizin askerlik durumunu seçiniz. Kadınlarda seçenek olarak Yükümlü Değil mevcuttur.<br>
						<br>
                        <b>Medeni Hali:</b> Personelin medeni durumunu seçiniz.<br>
						<br>
                        <b>Yabancı Dil:</b> Personelin var ise yabancı dilini seçiniz.<br>
						<br>
                        <b>Çocuk Sayısı:</b> Personelin resmi olarak üzerine kayıtlı çocuk sayısını giriniz.<br>
						<br>
  
					</p>

                    <span><hr></span>
                    <span class="text-red-500 font-bold">*</span> doldurulması zorunlu alanlardır.<br>
				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>
                <employee-tab-menu :employee-id='employeeId'></employee-tab-menu>
                <div class='col-12'>
                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Öğrenim Durumu<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="education" v-model="dataItem.education" :options="educationList" optionLabel="name" option-value='value' placeholder="Seçim Yapın"></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Mezun Olduğu Kurum</label>
                        <div class="col-12 md:col-10">
                            <InputText id="lastSchool" type="text" autocomplete='off' v-model='dataItem.lastSchool' />
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Askerlik Durumu<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="militaryStatus" v-model="dataItem.militaryStatus" :options="militaryStatuses" optionLabel="name" option-value='value' placeholder="Seçim Yapın"></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Medeni Hali<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="maritalStatus" v-model="dataItem.maritalStatus" :options="maritalStatuses" optionLabel="name" option-value='value' placeholder="Seçim Yapın"></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Yabancı Dil</label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="foreignLanguage" v-model="dataItem.foreignLanguage" :options="foreignLanguages" optionLabel="name" option-value='value' placeholder="Seçim Yapın"></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Çocuk Sayısı</label>
                        <div class="col-12 md:col-10">
                            <InputNumber id='childCount' autocomplete='off' v-model="dataItem.childCount" showButtons mode="decimal"></InputNumber>
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' @click='save'></Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {
    showErrorMessage,
    showSuccessMessage, showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeLeftPanel from './employeeLeftPanel';
import EmployeeTabMenu from './employeeTabMenu';
import EmployeeService from '../../services/employeeService';

import {
    getEducationList, getEmployee,
    getForeignLanguageList,
    getGenderList, getMaritalStatusList,
    getMilitaryStatusList,
} from '../common/commonFunctions';
import { getActiveProjectId, getUserType } from '../common/commonConstantFunctions';

export default {
    components: { EmployeeTabMenu, EmployeeLeftPanel, AdminToast },
    _employeeService: null,
    created() {
        this._employeeService = new EmployeeService();
        this.employeeId = this.$route.params.employeeId;
    },
    async mounted() {
        this.dataItem = await getEmployee(this.employeeId);
        this.projectId = getActiveProjectId();
        if(this.dataItem.activeProject != null && this.dataItem.activeProject.id != this.projectId && getUserType() != "Admin" )
        {
            this.$router.push({ name: 'access'});
            return;
        }
        this.educationList = await getEducationList();
        this.militaryStatuses = await getMilitaryStatusList();
        this.genders = await getGenderList();
        this.foreignLanguages = await getForeignLanguageList();
        this.maritalStatuses = await getMaritalStatusList();
        },
    data() {
        return {
            employeeId: 0,
            dataItem: {
                id: "",
                name: "",
                surName: "",
                isActive: "",
                insertDate: "",
                identityNumber: "",
                birthday: "",
                gender: "",
                education: "",
                lastSchool: "",
                militaryStatus: "",
                maritalStatus: "",
                foreignLanguage: "",
                childCount: "",
                address: "",
                cityId: "",
                townId: "",
                gsm: "",
                email: "",
                emergencyPersonName: "",
                emergencyPersonPhone: "",
                employeePhoto: "",
            },
            educationList: [],
            foreignLanguages: [],
            maritalStatuses: [],
            militaryStatuses: [],
            genders: [],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._employeeService.updateEmployeePersonelInformation(this.employeeId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Çalışan Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
                }
            }
            else {
                showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
            }

        },
        validateForm() {
            if (this.dataItem.education == null || this.dataItem.education == '') {
                showValidationMessage(this, 'Öğrenim Durumu Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.militaryStatus == null || this.dataItem.militaryStatus == '') {
                showValidationMessage(this, 'Askerlik Durumu Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.maritalStatus == null || this.dataItem.maritalStatus == '') {
                showValidationMessage(this, 'Medeni Hali Alanı Boş Bırakılamaz');
                return false;
            }
            return true;
        },
    },
};

</script>

